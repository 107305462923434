import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import Navigation from "../components/layout/Navigation";
import PageTransition from "../components/layout/PageTransition";
import SEO from "../components/helper/SEO";
import {graphql, StaticQuery} from "gatsby";
import MyPortableText from "../components/helper/MyPortableText";
import "../styles/global.scss";
import Icon from "../components/helper/Icon";

const Layout = ({children, location, data}) => {

    const staticSettings = data?.allSanitySettings?.edges[0]?.node;
    const [maintenanceMode, setMaintenanceMode] = useState(true);

    useEffect(() => {
        if (location.hostname !== ('kobrakasino.com')){
            setMaintenanceMode(false);
        }
    }, [location]);

    if (staticSettings?.maintenance.maintenance && maintenanceMode) {
        return (<>
            <SEO title={staticSettings?.maintenance_seo_title} description={staticSettings?.maintenance_seo_description} location={location}></SEO>
            <div className={"maintenance top-0 left-0 h-[100dvh] w-screen bg-white flex justify-center items-center flex-col px-10"} style={{
                height: '100dvh',
                width: '100vw'
            }}>
                <div className="mx-page">
                    <div className={"reveal-text"}>
                        <h1 className={`font-serif italic font-bold text-4xl medium:text-5xl tablet:text-7xl z-10 relative mb-5 center-aligned reveal`}>{staticSettings?.maintenance?.maintenancetitle}</h1>
                    </div>
                </div>
                <div className={"text-center mx-page reveal-text"}>
                    <MyPortableText value={staticSettings?.maintenance?._rawMaintenancetext}></MyPortableText>
                </div>
                <div className={"flex h-[10%] items-center"}>
                    {staticSettings?.sociallinks?.map((link, index) => {
                        return <a href={link?.value} target={"_blank"} key={index} className={"overflow-hidden hover-scale mx-1"}>
                            <div className={"reveal-text"} data-delay={ index+1 + "00ms"}>
                                <div className={"p-1.5 rounded-full bg-black"}>
                                    <Icon icon={link?.type}/>
                                </div>
                            </div>
                        </a>
                    })}
                </div>
            </div>
        </>)
    }
    else{
        return (
            <div className={"bg-white"}>
                <link rel="stylesheet" href="https://use.typekit.net/dii2rtn.css"/>
                <PageTransition location={location}>
                    {children}
                </PageTransition>
            </div>
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired, data: PropTypes.shape({
        allSanitySettings: PropTypes.shape({
            edges: PropTypes.shape({
                node: PropTypes.shape({
                    maintenance: PropTypes.string.isRequired,
                }),
            }).isRequired,
        }).isRequired,
    }).isRequired,
}


// 👇 Query component
export default function myLayout(props) {
    return (<StaticQuery
        query={graphql`
        query SiteTitleQuery {
          allSanitySettings{
                edges {
                    node {
                    sociallinks{
                    value
                    type
                    }
                        maintenance{
                         maintenance_seo_description
                        maintenance_seo_title
                        maintenancetitle
                        maintenancebutton {
                            title
                            link
                        }
                        _rawMaintenancetext
                        }
                      
                        address_street
                        address_nr
                        address_plz
                        address_ort
                        sociallinks{
                            value
                            title
                            type
                        }
                        email
                    }
                }
            }
        }
      `}
        render={data => <Layout data={data} {...props} />}
    />);
}
